import React, { useState, useEffect, useCallback } from 'react';
import { Box, Slider, TextField } from '@mui/material';
import styles from './Filter.module.scss';
import { ApiCallState } from '../../api';

export interface FilterNumberProps {
  value: [number | null, number | null];
  onValueChange: (value: [number | null, number | null]) => void;
  valueRange: ApiCallState<[number, number]>;
  unit?: 't' | 'kg';
  filterKey: string;
}

function FilterNumberRange(props: FilterNumberProps) {
  const { unit, value, onValueChange, valueRange } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [localValue, setLocalValue] = useState<number[]>([
    value[0] !== null ? value[0] : valueRange?.result?.[0] || 0,
    value[1] !== null ? value[1] : valueRange?.result?.[1] || 100,
  ]);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);
  const [textValues, setTextValues] = useState<[string, string]>([localValue[0].toString(), localValue[1].toString()]);

  useEffect(() => {
    if (valueRange.result?.[1] && (maxValue === undefined || valueRange.result[1] > maxValue)) {
      setMaxValue(valueRange.result[1]);
    }
  }, [valueRange.result, maxValue]);

  const updateValue = useCallback(() => {
    // only update if value has changed
    if (value[0] !== localValue[0] || value[1] !== localValue[1]) {
      onValueChange(localValue as [number | null, number | null]);
    }
  }, [value, localValue, onValueChange]);

  // set local value if value prop changes
  useEffect(() => {
    setLocalValue([!!value[0] ? value[0] : 0, !!value[1] ? value[1] : valueRange?.result?.[1] || localValue[1]]);
  }, [value]);

  function handleSliderChange(event: Event | React.SyntheticEvent, newValue: number | number[]) {
    if (Array.isArray(newValue)) {
      const [lower, upper] = newValue;
      // Ensure values are within bounds and lower <= upper
      const validLower = Math.max(0, Math.min(lower, upper));
      const validUpper = Math.min(maxValue ?? Infinity, Math.max(lower, upper));
      setLocalValue([validLower, validUpper]);
    }
  }

  function handleTextFieldChange(index: 0 | 1) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTextValues = [...textValues] as [string, string];
      newTextValues[index] = e.target.value;
      setTextValues(newTextValues);

      // Only update numerical values if we have valid input
      if (e.target.value === '') return;

      const parsedValue = parseInt(e.target.value);
      if (isNaN(parsedValue)) return;

      const newValue = [...localValue] as [number, number];
      if (index === 0) {
        newValue[0] = Math.min(parsedValue, newValue[1]);
      } else {
        newValue[1] = Math.max(parsedValue, newValue[0]);
      }

      newValue[0] = Math.max(0, newValue[0]);
      newValue[1] = Math.min(maxValue ?? Infinity, newValue[1]);

      setLocalValue(newValue);
      onValueChange(newValue as [number | null, number | null]);
    };
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      updateValue();
    }
  }

  useEffect(() => {
    if (!valueRange.result) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [valueRange]);

  useEffect(() => {
    setTextValues([localValue[0].toString(), localValue[1].toString()]);
  }, [localValue]);

  return (
    <Box
      className={`${styles.flex_container} ${styles.flex_vertical} ${styles.flex_container_width} ${styles.centered}`}
    >
      <Box
        className={`${styles.flex_container_width} ${styles.centered}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '1rem',
          backgroundColor: '#f2f2f2',
          padding: '1rem',
          marginBottom: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <Slider
          value={localValue}
          onChange={handleSliderChange}
          onChangeCommitted={updateValue}
          valueLabelDisplay='auto'
          valueLabelFormat={(v) => `${v}${unit}`}
          min={0}
          max={maxValue}
          disabled={isLoading}
          disableSwap
        />
        {unit && <span>{unit}</span>}
      </Box>
      <Box
        className={`${styles.flex_container_width} ${styles.centered}`}
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}
      >
        <TextField
          type='number'
          size='small'
          value={textValues[0]}
          onChange={handleTextFieldChange(0)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
        <hr
          style={{
            borderTop: '1px solid darkgray',
            width: '1rem',
            height: '0px',
          }}
        />
        <TextField
          type='number'
          size='small'
          value={textValues[1]}
          onChange={handleTextFieldChange(1)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
}

export default React.memo(FilterNumberRange);
