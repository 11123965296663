/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
  DataGridPro,
  deDE,
  GridColDef,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { SortDTO } from '../../api';
import { Paper, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DeliveryDTO, DeliveryFilterDTO, sortDirection } from '../../api/generated';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { columnNameMapping } from '../../pages/Documentation';
import { DeliveriesContext, SortPaginateContext } from '../../context/Documentation.context';

export interface TableProps {
  onRowSelect: (value: DeliveryDTO, itemIndex: number) => void;
  requestedRowIndex: number;
  pageSize: number;
}

export function Table(props: TableProps) {
  const columns: GridColDef[] = [
    {
      field: 'delivery_id',
      headerName: columnNameMapping.delivery_id,
      resizable: false,
      flex: 0.5,
      type: 'string',
    },
    {
      field: 'supplier',
      headerName: columnNameMapping.supplier,
      resizable: false,
      flex: 0.7,
      type: 'string',
    },
    {
      field: 'material_id',
      headerName: columnNameMapping.material_id,
      resizable: false,
      flex: 0.5,
      type: 'string',
    },
    {
      field: 'material',
      headerName: columnNameMapping.material,
      resizable: false,
      flex: 0.7,
      type: 'string',
    },
    {
      field: 'avv',
      headerName: columnNameMapping.avv,
      resizable: false,
      flex: 0.5,
      type: 'string',
    },
    {
      field: 'date',
      headerName: columnNameMapping.date,
      resizable: false,
      flex: 0.8,
      type: 'dateTime',
    },
    {
      field: 'has_truck_scan_img',
      headerName: columnNameMapping.has_truck_scan_img,
      resizable: false,
      flex: 0.3,
      type: 'boolean',
      filterable: true,
      renderCell: (params) => {
        if (params.value)
          return (
            <Tooltip title='Bild vorhanden' arrow>
              <CheckCircleIcon />
            </Tooltip>
          );
        else
          return (
            <Tooltip title='Warnung: Kein Bild vorhanden!' arrow>
              <ErrorOutlineIcon />
            </Tooltip>
          );
      },
    },
    {
      field: 'netto_weight',
      headerName: columnNameMapping.netto_weight,
      resizable: false,
      flex: 0.5,
      type: 'number',
    },
    {
      field: 'numberplate',
      headerName: columnNameMapping.numberplate,
      resizable: false,
      flex: 0.5,
      type: 'string',
    },
    {
      field: 'construction_site',
      headerName: columnNameMapping.construction_site,
      resizable: false,
      flex: 1,
      type: 'string',
    },
  ];

  const { sortPaginateState, sortPaginateDispatch } = React.useContext(SortPaginateContext);
  const deliveries = React.useContext(DeliveriesContext);

  const apiRef = useGridApiRef();

  function handleSortChange(model: GridSortModel): void {
    if (model.length === 0) return;
    const sort: SortDTO<DeliveryFilterDTO> = {
      sortField: model[0].field as keyof DeliveryFilterDTO,
      sortDirection: model[0].sort as sortDirection,
    };
    sortPaginateDispatch({ type: 'sort.update', payload: sort });
  }

  function notifyChange(newlySelectedRowID: GridRowId) {
    // This is the case if a row is still selected but is not shown anymore because a filter was applied that hides it
    if (!newlySelectedRowID) return;

    const delivery: DeliveryDTO = deliveries.result?.data.find((d) => d.id === newlySelectedRowID) as DeliveryDTO;

    const selectedDeliveryDataGridRowIndex: number =
      apiRef.current.getRowIndexRelativeToVisibleRows(newlySelectedRowID);

    if (props.onRowSelect) {
      props.onRowSelect(delivery, selectedDeliveryDataGridRowIndex);
    }
  }

  React.useEffect(() => {
    if (!props.requestedRowIndex && props.requestedRowIndex !== 0) return; // Catch undefined and null but not 0
    const allRowIds: GridRowId[] = apiRef.current.getAllRowIds();
    const rowIdForNextRow: string = allRowIds[props.requestedRowIndex] as string;
    if (!rowIdForNextRow) return;
    apiRef.current.selectRow(rowIdForNextRow, true, true);
  }, [props.requestedRowIndex]);

  return (
    // NOTE: only use loading guard for initial loading; all subsequent updates are handled by the data grid
    <Paper
      className='data-grid'
      elevation={8}
      sx={{
        borderRadius: '10px',
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <ApiLoadingGuard apiStates={[deliveries]}>
        <DataGridPro
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          apiRef={apiRef}
          columns={columns}
          rows={
            deliveries.result?.data.map((d) => {
              return { ...d, date: new Date(d.date), has_truck_scan_img: !!d.pictureIds?.length };
            }) || []
          }
          disableMultipleSelection
          loading={!deliveries?.result}
          disableMultipleColumnsSorting
          disableColumnPinning
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          pagination
          sortingMode='server'
          sortingOrder={['asc', 'desc']}
          filterMode='server'
          paginationMode='server'
          rowsPerPageOptions={[props.pageSize]} // Having only one option removes the dropdown menu
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: sortPaginateState.sortField,
                  sort: sortPaginateState.sortDirection,
                },
              ],
            },
            pagination: { pageSize: props.pageSize, page: sortPaginateState.offset / props.pageSize },
          }}
          onSortModelChange={(model) => handleSortChange(model)}
          // This is used because it is triggered on click AND by next / previous buttons in DetailView
          onSelectionModelChange={(selectionModel: GridSelectionModel) =>
            // Because multiple selection is disabled this returns the selected row id
            notifyChange(selectionModel[0])
          }
          rowCount={deliveries.result?.total || 0}
          onPageChange={(number) =>
            sortPaginateDispatch({
              type: 'paginate.update',
              payload: {
                offset: number * props.pageSize,
                limit: sortPaginateState.limit,
              },
            })
          }
        />
      </ApiLoadingGuard>
    </Paper>
  );
}
