import React, { createContext, PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSpinner } from './SpinnerContext';
import {
  JobsiteDTO,
  JobsiteService,
  client,
  OrganisationService,
  UserPopulatedDTO,
  UserService,
} from './api/generated';
import qs from 'qs';
export interface UserContextProps {
  user?: UserPopulatedDTO;
  reloadUser: () => void;
}

export const UserContext = createContext<UserContextProps>({
  reloadUser: () => {},
});

export function UserProvider(props: PropsWithChildren) {
  const { setLoading } = useSpinner();
  const { getAccessTokenSilently, user: auth0user } = useAuth0();
  const [user, setUser] = React.useState<UserPopulatedDTO>();
  const [reloadTrigger, setReloadTrigger] = React.useState<number>(0);

  function reloadUser() {
    setReloadTrigger((prev) => prev + 1);
  }

  client.interceptors.request.use(async (request, options) => {
    request.headers.set('Authorization', `Bearer ${await getAccessTokenSilently()}`);
    return request;
  });

  client.setConfig({
    querySerializer: (query) => qs.stringify(query, { encode: true }),
  });

  React.useEffect(() => {
    (async (): Promise<UserPopulatedDTO> => {
      setLoading(true);
      setUser(undefined);
      if (!auth0user) return Promise.reject(new Error('No user'));
      await UserService.userControllerLoginUser();
      const baseUserResp = await UserService.userControllerGetUser({ path: { authId: auth0user?.sub || '' } });
      if (baseUserResp.error || !baseUserResp.data) {
        return Promise.reject(new Error());
      }
      const baseUser = baseUserResp.data;
      const organisationResp = await OrganisationService.organisationControllerGetOrganisation({
        path: {
          organisationId: baseUser.organisationId,
        },
      });
      if (organisationResp.error || !organisationResp.data) {
        return Promise.reject(new Error());
      }
      const organisation = organisationResp.data;

      let jobsites: JobsiteDTO[] = [];
      if (baseUser.jobsiteIds.length > 0) {
        jobsites =
          (
            await JobsiteService.jobsiteControllerGetJobsites({
              path: {
                organisationId: baseUser.organisationId,
              },
              query: {
                filter: [
                  {
                    _id: { in: baseUser.jobsiteIds },
                  },
                ],
              },
            })
          ).data?.data || [];
      }

      const preferredJobsite = jobsites.find((j) => j.id === baseUser.preferredJobsiteId);

      return {
        ...baseUser,
        organisation,
        jobsites: jobsites,
        preferredJobsite,
      };
    })()
      .then((result) => {
        setUser(result);
      })
      .catch((err) => {
        console.error({ error: `${err.message}` });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadTrigger, auth0user]);

  return <UserContext.Provider value={{ user, reloadUser }}>{props.children}</UserContext.Provider>;
}
