import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Chip,
  Popper,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styles from './Filter.module.scss';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import { ApiCallState } from '../../api';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const { key, ...optionProps } = dataSet[0];

  return (
    <Box
      key={key}
      display='flex'
      flexDirection='row'
      justifyItems='space-between'
      alignItems='center'
      width='100%'
      style={inlineStyle}
      {...optionProps}
    >
      <Typography component='li' noWrap flex={1} textOverflow='hidden' textAlign='left'>
        {dataSet[1]}
      </Typography>
      <Typography component='li' noWrap flex={1} textAlign='end'>
        {dataSet[2] ?? 0}
      </Typography>
    </Box>
  );
}

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData: React.ReactElement<unknown>[] = [];
  (children as React.ReactElement<unknown>[]).forEach(
    (
      item: React.ReactElement<unknown> & {
        children?: React.ReactElement<unknown>[];
      }
    ) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement<unknown>) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export interface FilterStringSelectProps {
  options: ApiCallState<Array<{ value: string; count?: number }>>;
  value: string[];
  onValueChange: (value: string[]) => void;
}

function FilterStringSelect(props: FilterStringSelectProps) {
  const [localValue, setLocalValue] = useState<string[]>(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const localOptions = React.useMemo(() => {
    return props.options.result || [];
  }, [props.options.result]);

  return (
    <Autocomplete
      className={`${styles.centered} ${styles.flex_container_width}`}
      multiple
      disableCloseOnSelect
      disabled={!props.options.result}
      options={localOptions.map((o) => o?.value) || []}
      value={localValue}
      onChange={(e, v) => setLocalValue(v)}
      onBlur={() => props.onValueChange(localValue)}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          // remove onDelete from props
          let props: any = getTagProps({ index });
          delete props.onDelete;
          return <Chip label={option} {...props} />;
        });
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      renderInput={(params) => <TextField {...params} size='small' fullWidth />}
      renderOption={(props, option, state) => {
        const count = localOptions.find((o) => o.value === option)?.count;
        return [props, option, count] as React.ReactNode;
      }}
    />
  );
}

export default memo(FilterStringSelect);
