import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Documentation } from './pages/Documentation';
import { LiveView } from './pages/LiveView';
import { NoPage } from './pages/NoPage';
import { UserAdminPage } from './pages/UserAdminPage';
import { config } from './config';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { InitialJobsiteSelect } from './pages/InitialJobsiteSelect';
import { UserContext, UserProvider } from './UserContext';
import { FilterContextProvider } from './context/Documentation.context';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate('/');
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function UserGuardDocumentation(): JSX.Element {
  const AuthedDocumentation = withAuthenticationRequired(Documentation);

  const { user } = React.useContext(UserContext);

  //At this point we have an incosistent state --> return null
  if (user) {
    if (user.preferredJobsite) {
      return (
        <FilterContextProvider>
          <AuthedDocumentation />
        </FilterContextProvider>
      );
    } else {
      return UserGuardInitialJobsiteSelect();
    }
  } else {
    return null as any;
  }
}

function UserGuardLivePrediction(): JSX.Element {
  const AuthedLivePrediction = withAuthenticationRequired(LiveView);

  const { user } = React.useContext(UserContext);

  //At this point we have an incosistent state --> return null
  if (user) {
    if (user.preferredJobsite) {
      return <AuthedLivePrediction />;
    } else {
      return UserGuardInitialJobsiteSelect();
    }
  } else {
    return null as any;
  }
}

function UserGuardInitialJobsiteSelect(): JSX.Element {
  const AuthedInitialJobsiteSelect = withAuthenticationRequired(InitialJobsiteSelect);

  const { user } = React.useContext(UserContext);

  //At this point we have an incosistent state --> return null
  if (user) {
    return <AuthedInitialJobsiteSelect />;
  } else {
    return null as any;
  }
}

function UserGuardAdministration(): JSX.Element {
  const AuthedUserAdminPage = withAuthenticationRequired(UserAdminPage);

  const { user } = React.useContext(UserContext);

  //At this point we have an incosistent state --> return null
  if (user) {
    return <AuthedUserAdminPage />;
  } else {
    return null as any;
  }
}

export function AppRouter() {
  const AuthedNavigate = withAuthenticationRequired(Navigate);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.auth0.audience,
        }}
      >
        <UserProvider>
          <Routes>
            <Route path='/' element={<AuthedNavigate to='documentation' />} />
            <Route path='documentation' element={<UserGuardDocumentation />} />
            <Route path='live-prediction' element={<UserGuardLivePrediction />} />
            <Route path='initial-jobsite-select' element={<UserGuardInitialJobsiteSelect />} />
            <Route path='user-admin' element={<UserGuardAdministration />} />
            <Route path='*' element={<NoPage />} />
          </Routes>
        </UserProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}
