import React from 'react';
import { Card, Typography } from '@mui/material';
import { ApiCallState } from '../../api';
import { useSnackbar } from '../SnackbarProvider';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { PredictionError } from './PredictionError';
import { UserContext } from '../../UserContext';
import { theme } from '../../Theme';
import { BarChart, BarSeriesType } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { Activity } from '../../pages/LiveView';
import { ActivityService, MaterialClassificationDTO, PredictionDTO } from '../../api/generated';

export interface PredictionProps {
  onFeedbackChange?: (feedback: MaterialClassificationDTO[], activity: Activity) => void;
  activity: ApiCallState<Activity>;
  height?: string;
}

export function Prediction(props: PredictionProps) {
  const { showSnackbar } = useSnackbar();
  const { user } = React.useContext(UserContext);

  const [prevActivity, setPrevActivity] = React.useState<ApiCallState<Activity>>({ result: undefined });
  const [prediction, setPrediction] = React.useState<ApiCallState<PredictionDTO>>({ result: undefined });
  const [series, setSeries] = React.useState<MakeOptional<BarSeriesType, 'type'>[]>([]);

  const padding: string = '1rem';

  function buildFeedback(checked_material: string): MaterialClassificationDTO[] {
    return (
      prediction.result?.estimate.map((material, index) => {
        if (material.material === checked_material) {
          return { ...material, probability: 1 };
        } else {
          return { ...material, probability: 0 };
        }
      }) || []
    );
  }

  // getting data from api
  React.useEffect(() => {
    if (props.activity.result === undefined) {
      return;
    }
    if (prevActivity.result?.capture_start_at === props.activity.result?.capture_start_at) {
      return;
    }
    setPrevActivity(props.activity);
    setPrediction({ result: undefined });
    let active = true;
    (async () => {
      // get prediction
      const responsePrediction = await ActivityService.activityControllerGetActivityPrediction({
        path: {
          jobsiteId: user?.preferredJobsite?.id || '',
          capture_start_at: props.activity.result?.capture_start_at || '',
        },
      });
      if (responsePrediction.error) {
        showSnackbar('Fehler beim Abrufen der Prognose', 'error');
        return;
      }
      const prediction = responsePrediction.data;
      if (!prediction || !prediction.estimate || !prediction.estimate.length) {
        showSnackbar('Keine Prognose verfügbar', 'warning');
        return;
      }
      if (active) {
        setPrediction({ result: prediction });
      }

      if (props.activity?.result?.hasFeedback) {
        return;
      }

      // get feedback
      const responseFeedback = await ActivityService.activityControllerGetFeedbacks({
        path: {
          jobsiteId: user?.preferredJobsite?.id || '',
          capture_start_at: props.activity.result?.capture_start_at || '',
        },
      });

      if (responseFeedback.error) {
        showSnackbar('Fehler beim Abrufen des Feedbacks', 'error');
        return;
      }

      const feedback = responseFeedback.data;
      if (!feedback || !feedback.estimate || !feedback.estimate.length) {
        return;
      }

      if (props.activity.result) {
        props.activity.result.feedback = feedback.estimate;
      }

      return () => {
        active = false;
      };
    })();
  }, [props.activity.result]);

  React.useEffect(() => {
    if (prediction.result === undefined) {
      return;
    }
    if (!props.activity?.result?.hasFeedback) {
      setSeries([
        {
          label: 'Prognose',
          data: prediction.result?.estimate.map((x) => {
            return x.probability * 100;
          }),
          color: theme.palette.primary.light,
        },
      ]);
    } else {
      setSeries([
        {
          label: 'Prognose',
          data: prediction.result?.estimate.map((x) => {
            return x.probability * 100;
          }),
          color: theme.palette.primary.light,
        },
        {
          label: 'Feedback',
          data: props.activity?.result?.feedback.map((frac, index) => {
            return frac.probability * 100;
          }),
          color: theme.palette.success.light,
        },
      ]);
    }
  }, [prediction, props.activity?.result?.feedback]);

  async function handleSetFeedback(selected_material: string) {
    if (props.activity.result === undefined) {
      return;
    }
    const checked = props.activity?.result?.feedback.some(
      (x) => x.material === selected_material && x.probability === 1
    );
    if (checked) {
      try {
        await ActivityService.activityControllerDeleteFeedback({
          path: {
            jobsiteId: user?.preferredJobsite?.id || '',
            capture_start_at: props.activity?.result?.capture_start_at || '',
          },
        });
        showSnackbar('Feedback gelöscht', 'info');
      } catch (error) {
        showSnackbar('Fehler beim Löschen des Feedbacks', 'error');
      }

      props.activity.result.feedback = [];
      return;
    }

    const fdbck = buildFeedback(selected_material);
    props.activity.result.feedback = fdbck;

    try {
      await ActivityService.activityControllerAddFeedback({
        path: {
          jobsiteId: user?.preferredJobsite?.id || '',
          capture_start_at: props.activity?.result?.capture_start_at || '',
        },
        body: { feedback: fdbck },
      });
      showSnackbar('Feedback gespeichert', 'success');
    } catch (error) {
      showSnackbar('Fehler beim Speichern des Feedbacks', 'error');
    }
  }

  return (
    <Card
      sx={{
        borderRadius: '10px',
        padding: `${padding}`,
        display: 'flex',
        flexDirection: 'column',
        minWidht: theme.breakpoints.values.md,
        height: props.height ? props.height : `calc(100% - 2 * ${padding})`,
        minHeight: '30vh', // This is necessary for viewports that are higher than widefor viewports that are higher than wide
        position: 'relative',
      }}
      elevation={8}
    >
      <Typography variant='h6'>Materialprognose</Typography>
      <ApiLoadingGuard apiStates={[props.activity, prediction]} error_display={PredictionError}>
        <BarChart
          margin={{ top: 10, right: 100, bottom: 40, left: 40 }}
          xAxis={[{ data: prediction.result?.estimate.map((x) => x.displayName), scaleType: 'band' }]}
          yAxis={[{ scaleType: 'linear', min: 0, max: 100 }]}
          series={series}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: 0,
            },
          }}
          slots={{
            // Tooltips
            axisContent: (_) => {
              return (
                <Card
                  sx={{
                    borderRadius: '10px',
                    padding: 1,
                  }}
                  variant='outlined'
                >
                  <Typography variant='h6'>Materialfeedback geben</Typography>
                </Card>
              );
            },
          }}
          onAxisClick={(_, data) => {
            const material: string = prediction.result!!.estimate[data!!.dataIndex].material;
            handleSetFeedback(material);
          }}
        />
      </ApiLoadingGuard>
    </Card>
  );
}
