import { memo } from 'react';
import styles from './Filter.module.scss';
import { FormControl, FormControlLabel, Radio, RadioGroup, List, ListItem, Box, Typography } from '@mui/material';

export interface FilterEnumOneProps {
  filterKey: string;
  options: Array<{ value: string; name: string; count?: number }>;
  value?: string | boolean;
  onValueChange: (value: string) => void;
}

function FilterEnumOne({ options, value, onValueChange }: FilterEnumOneProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value);
  };

  return (
    <FormControl component='fieldset' className={`${styles.enum_list}`} sx={{ marginTop: '-1rem' }}>
      <RadioGroup value={value} onChange={handleChange}>
        <List>
          {options?.map((option) => (
            <ListItem key={option.name} disablePadding disableGutters>
              <FormControlLabel
                sx={{ width: '100%' }}
                value={option.value}
                disableTypography
                label={
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                  >
                    <Typography textAlign='left'>{option.name}</Typography>
                    <Typography noWrap textAlign='right'>
                      {option.count ?? 0}
                    </Typography>
                  </Box>
                }
                control={<Radio checked={option.value.toString() === value?.toString()} />}
              />
            </ListItem>
          ))}
        </List>
      </RadioGroup>
    </FormControl>
  );
}

export default memo(FilterEnumOne);
