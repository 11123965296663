import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import styles from './Filter.module.scss';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { memo } from 'react';

export interface FilterDateRangeProps {
  filterKey: string;
  dateRange?: [DateTime | undefined, DateTime | undefined];
  onValueChange: (value: DateRange<DateTime>) => void;
  value: [DateTime | null, DateTime | null];
}

function FilterDateRange(props: FilterDateRangeProps) {
  return (
    <DateTimeRangePicker
      className={`${styles.flex_container_width}`}
      views={['day', 'hours', 'minutes']}
      minDate={props.dateRange?.[0]}
      maxDate={props.dateRange?.[1]}
      disableFuture
      ampm={false}
      displayWeekNumber
      sx={{
        '& .MuiFormControl-root': {
          margin: '0',
        },
      }}
      slotProps={{
        textField: { size: 'small', fullWidth: true },
        fieldRoot: {
          style: { flexDirection: 'column', gap: '1rem', margin: 'auto' },
        },
        fieldSeparator: { style: { display: 'none' } },
        desktopPaper: { sx: { marginTop: '4rem' } },
      }}
      onAccept={props.onValueChange}
      value={props.value}
    />
  );
}

export default memo(FilterDateRange);
