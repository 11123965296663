import React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { TextField, Button } from '@mui/material';
import Card from '@mui/material/Card';
import { RefetchProp } from './UserGrid';
import { useSnackbar } from '../SnackbarProvider';
import { UserCreateDTO, UserService } from '../../api/generated';
import { UserContext } from '../../UserContext';

export function CreateUser({ refetch, setRefetch }: RefetchProp): JSX.Element {
  const { user } = React.useContext(UserContext);

  const { showSnackbar } = useSnackbar();

  const [newUser, setNewUser] = useState<UserCreateDTO>({
    firstName: '',
    lastName: '',
    email: '',
    admin: false,
    organisationId: user?.organisation.id || '',
  });

  function handleAddUser() {
    (async () => {
      return await UserService.userControllerCreateUser({ body: newUser });
    })()
      .then(() => {
        showSnackbar('Nutzer/in erfolgreich hinzugefügt', 'success');
        setRefetch(!refetch);
      })
      .catch((err) => {
        showSnackbar(err.message, 'error');
      });
  }

  return (
    <>
      <Typography variant='h5' sx={{ marginTop: 2 }}>
        Benutzerverwaltung
      </Typography>
      <Card sx={{ borderRadius: '10px', padding: 2, marginTop: 1, display: 'flex' }} elevation={8}>
        <TextField
          sx={{ marginRight: 2, flexGrow: 1 }}
          id='firstname'
          label='Vorname'
          value={newUser.firstName}
          onChange={(e) => {
            setNewUser({ ...newUser, firstName: e.target.value });
          }}
        />
        <TextField
          sx={{ marginRight: 2, flexGrow: 1 }}
          id='lastname'
          label='Nachname'
          value={newUser.lastName}
          onChange={(e) => {
            setNewUser({ ...newUser, lastName: e.target.value });
          }}
        />
        <TextField
          sx={{ marginRight: 2, flexGrow: 1 }}
          id='email'
          label='E-Mail'
          value={newUser.email}
          onChange={(e) => {
            setNewUser({ ...newUser, email: e.target.value });
          }}
        />
        <Button
          sx={{ flexGrow: 1 }}
          variant='contained'
          onClick={handleAddUser}
          disabled={Object.entries(newUser).some(
            ([key, value]) => typeof value === 'string' && value.length < 3 && key !== 'organisationId'
          )}
        >
          Nutzer/in Einladen
        </Button>
      </Card>
    </>
  );
}
