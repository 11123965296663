import React, { memo, useCallback, useContext, useMemo } from 'react';
import { Box, Button, Card, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './FilterHeader.module.scss';
import TuneIcon from '@mui/icons-material/Tune';
import {
  BooleanFilterDTO,
  DateFilterDTO,
  DeliveryFilterDTO,
  FloatFilterDTO,
  StringFilterDTO,
} from '../../api/generated';
import { columnNameMapping } from '../../pages/Documentation';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { FilterContext } from '../../context/Documentation.context';

const DATE_DISPLAY_OPTIONS: DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const transformBooleanFilter = (key: keyof DeliveryFilterDTO, value: BooleanFilterDTO): FilterItem => ({
  key,
  value: value.eq ? 'Ja' : 'Nein',
  name: columnNameMapping[key] || key,
});

const transformStringFilter = (key: keyof DeliveryFilterDTO, value: StringFilterDTO): FilterItem[] =>
  value.in?.map((val) => ({ key, value: val, name: columnNameMapping[key] || key })) || [];

const formatDateRange = (gte: string | undefined, lt: string | undefined): string => {
  const gteDateTime = gte && DateTime.fromISO(gte);
  const ltDateTime = lt && DateTime.fromISO(lt);

  if (gteDateTime && ltDateTime) {
    return `${gteDateTime.toLocaleString(DATE_DISPLAY_OPTIONS)} - ${ltDateTime.toLocaleString(DATE_DISPLAY_OPTIONS)}`;
  }
  if (gteDateTime) {
    return `Ab ${gteDateTime.toLocaleString(DATE_DISPLAY_OPTIONS)}`;
  }
  if (ltDateTime) {
    return `Bis ${ltDateTime.toLocaleString(DATE_DISPLAY_OPTIONS)}`;
  }
  return '';
};

const transformDateFilter = (value: DateFilterDTO): FilterItem | null => {
  if ('range' in value) {
    return {
      key: 'date',
      value: formatDateRange(value.range?.gte, value.range?.lt),
      name: columnNameMapping.date || 'date',
    };
  }
  return null;
};

const formatNumberRange = (key: keyof DeliveryFilterDTO, gte?: number, lte?: number): string => {
  const unitSuffix = key === 'netto_weight' ? 't' : '';
  const normalizedGte = key === 'netto_weight' && gte ? gte / 1000 : gte;
  const normalizedLte = key === 'netto_weight' && lte ? lte / 1000 : lte;

  if (normalizedGte && normalizedLte) {
    return `${normalizedGte}${unitSuffix} - ${normalizedLte}${unitSuffix}`;
  }
  if (normalizedGte) {
    return `Ab ${normalizedGte}${unitSuffix}`;
  }
  if (normalizedLte) {
    return `Bis ${normalizedLte}${unitSuffix}`;
  }
  return '';
};

const transformNumberFilter = (key: keyof DeliveryFilterDTO, value: FloatFilterDTO): FilterItem | null => {
  if ('range' in value) {
    return {
      key,
      value: formatNumberRange(key, value.range?.gte, value.range?.lte),
      name: columnNameMapping[key] || key,
    };
  }
  return null;
};

const transformFilterEntry = (
  key: keyof DeliveryFilterDTO,
  value: BooleanFilterDTO | StringFilterDTO | DateFilterDTO | FloatFilterDTO
): FilterItem[] => {
  if ('eq' in value) {
    return [transformBooleanFilter(key, value)];
  }
  if ('in' in value) {
    return transformStringFilter(key, value);
  }
  if (key === 'date') {
    const result = transformDateFilter(value as DateFilterDTO);
    return result ? [result] : [];
  }
  if ('range' in value) {
    const result = transformNumberFilter(key, value as FloatFilterDTO);
    return result ? [result] : [];
  }
  return [];
};

interface FilterHeaderProps {
  onShowFilterPanel: () => void;
}

type FilterValue = string | number | boolean;

interface FilterItem {
  key: keyof DeliveryFilterDTO;
  name: string;
  value: FilterValue;
}

type FilterEntries = Array<FilterItem>;

function FilterHeader({ onShowFilterPanel }: FilterHeaderProps) {
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { filterState, filterDispatch } = useContext(FilterContext);

  const filterList: FilterEntries = useMemo(() => {
    if (!filterState.filter) {
      return [];
    }

    const list = Object.entries(filterState.filter).flatMap(([origKey, value]) => {
      const key = origKey as keyof DeliveryFilterDTO;
      return transformFilterEntry(key, value);
    });

    return list.map((f) => ({
      key: f.key,
      name: columnNameMapping[f.key] || f.key,
      value: f.value,
    }));
  }, [filterState.filter]);

  const onResetFilter = useCallback(() => {
    filterDispatch({ type: 'filter.reset' });
  }, [filterDispatch]);

  const onRemoveFilter = useCallback(
    (key: keyof DeliveryFilterDTO, value: string | number | boolean) => {
      if (typeof value === 'boolean' || typeof value === 'number' || DateTime.isDateTime(value)) {
        filterDispatch({ type: 'filter.remove', payload: { key } });
      } else {
        // keep all other values
        filterDispatch({
          type: 'filter.upsert',
          payload: {
            key,
            data: { in: ((filterState?.filter?.[key] as StringFilterDTO)?.in || []).filter((v) => v !== value) },
          },
        });
      }
    },
    [filterDispatch, filterState.filter]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        justifyItems: 'center',
        marginBottom: 1,
      }}
    >
      {isXsOrSm && (
        <Button variant='contained' onClick={onShowFilterPanel} className={styles.filter_button}>
          <TuneIcon sx={{ mr: 1 }} />
          Filter
        </Button>
      )}
      <Button
        variant='contained'
        onClick={onResetFilter}
        disabled={filterList.length === 0}
        className={styles.filter_button}
        disableElevation
      >
        Alle Filter löschen
      </Button>
      {filterList.map((filter, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
          }}
          className={styles.filter_button}
        >
          <Typography variant='body2' sx={{ ml: 1 }}>{`${filter.name}: ${filter.value}`}</Typography>
          <IconButton onClick={() => onRemoveFilter(filter.key, filter.value)} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}

export default memo(FilterHeader);
