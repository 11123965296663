import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { DeliveryFilterDTO, DeliveryFilterOptionsDTO, DeliveryService, DeliveryDTO } from '../api/generated';
import { ApiCallState, equalFilterDTO, FilterDTO, PaginationDTO, SortDTO } from '../api';
import { UserContext } from '../UserContext';

// --- Filter
export interface FilterState {
  filter: DeliveryFilterDTO;
  filterOptions: ApiCallState<DeliveryFilterOptionsDTO>;
}
export interface FilterDispatch {
  type: FilterRecuderActions;
  payload?: FilterDispatchPayload | ApiCallState<DeliveryFilterOptionsDTO>;
}

export type FilterRecuderActions =
  | 'filter.reset'
  | 'filter.upsert'
  | 'filter.remove'
  | 'filterOptions.update'
  | 'filterOptions.reset';

export interface FilterDispatchPayload {
  key: keyof DeliveryFilterDTO;
  data?: FilterDTO;
}

function filterReducer(state: FilterState, action: FilterDispatch): FilterState {
  switch (action.type) {
    case 'filter.reset':
      return { filter: {}, filterOptions: state.filterOptions };

    case 'filter.upsert':
      if (action.payload) {
        const { key, data } = action.payload as FilterDispatchPayload;
        if (equalFilterDTO(state.filter[key], data)) return state;
        return { ...state, filter: { ...state.filter, [key]: data } };
      }
      break;
    case 'filter.remove':
      if (action.payload) {
        const { key } = action.payload as FilterDispatchPayload;
        // check if key exists
        if (!state.filter[key]) return state;
        const { [key]: _, ...rest } = state.filter;
        return { ...state, filter: rest };
      }
      break;
    case 'filterOptions.update':
      if (action.payload) {
        return { filter: state.filter, filterOptions: action.payload as ApiCallState<DeliveryFilterOptionsDTO> };
      }
      break;
    case 'filterOptions.reset':
      return { filter: state.filter, filterOptions: {} };
    default:
      return state;
  }
  return state;
}

export type FilterContextProps = { filterState: FilterState; filterDispatch: React.Dispatch<FilterDispatch> };

const initialFilterState: FilterState = { filter: {}, filterOptions: {} };
export const FilterContext = createContext<FilterContextProps>({
  filterState: initialFilterState,
  filterDispatch: () => {},
});

// --- Sort and Paginate
export type SortPaginateState = SortDTO<DeliveryFilterDTO> & PaginationDTO;
export interface SortPaginateDispatch {
  type: 'sort.update' | 'paginate.update' | 'pagination.reset';
  payload?: SortDTO<DeliveryFilterDTO> | PaginationDTO;
}

const initialSortPaginate: SortPaginateState = {
  sortField: 'date',
  sortDirection: 'desc',
  offset: 0,
  limit: 100,
};
interface SortPaginateContextProps {
  sortPaginateState: SortPaginateState;
  sortPaginateDispatch: React.Dispatch<SortPaginateDispatch>;
}

function sortPaginateReducer(state: SortPaginateState, action: SortPaginateDispatch): SortPaginateState {
  switch (action.type) {
    case 'sort.update':
      return { ...state, ...(action.payload as SortDTO<DeliveryFilterDTO>) };
    case 'paginate.update':
      return { ...state, ...(action.payload as PaginationDTO) };
    case 'pagination.reset':
      return { ...state, offset: 0 };
    default:
      return state;
  }
}

export const SortPaginateContext = createContext<SortPaginateContextProps>({
  sortPaginateState: initialSortPaginate,
  sortPaginateDispatch: () => {},
});

// --- Deliveries
export type DeliveriesState = ApiCallState<{
  data: DeliveryDTO[];
  total: number;
}>;
const initialDeliveries: DeliveriesState = {};
export const DeliveriesContext = createContext<DeliveriesState>(initialDeliveries);

// --- FilterContextProvider
export function FilterContextProvider({ children }: { children: React.ReactNode }) {
  const { user } = useContext(UserContext);

  const [filterState, filterDispatch] = useReducer(filterReducer, initialFilterState);
  const [sortPaginateState, sortPaginateDispatch] = useReducer(sortPaginateReducer, initialSortPaginate);
  const [deliveries, setDeliveries] = useState<DeliveriesState>({});

  // Reset pagination when filter changes
  useEffect(() => {
    sortPaginateDispatch({ type: 'pagination.reset' });
  }, [filterState.filter]);

  // update deliveries
  useEffect(() => {
    setDeliveries({});
    (async () => {
      const filter = filterState.filter;
      const sortPaginate = sortPaginateState;

      const dataResp = await DeliveryService.deliveryControllerGetDeliveries({
        path: { jobsiteId: user?.preferredJobsite?.id || '' },
        query: { filter: [filter], ...sortPaginate },
      });

      const filterOptionsResp = await DeliveryService.deliveryControllerGetFilterOptions({
        path: { jobsiteId: user?.preferredJobsite?.id || '' },
        query: { filter: [filter] },
      });
      return { dataResp, filterOptionsResp };
    })().then((response) => {
      const { dataResp, filterOptionsResp } = response;

      if (!filterOptionsResp.data || filterOptionsResp.error) return;
      const filterOptions = filterOptionsResp.data;

      if (!dataResp.data || dataResp.error) return;

      filterDispatch({ type: 'filterOptions.update', payload: { result: filterOptions } });
      setDeliveries({ result: { ...dataResp.data, data: dataResp.data.data || [] } });
    });
  }, [filterState.filter, sortPaginateState, user?.preferredJobsite?.id]);

  return (
    <FilterContext.Provider value={{ filterState: filterState, filterDispatch: filterDispatch }}>
      <SortPaginateContext.Provider
        value={{ sortPaginateState: sortPaginateState, sortPaginateDispatch: sortPaginateDispatch }}
      >
        <DeliveriesContext.Provider value={deliveries}>{children}</DeliveriesContext.Provider>
      </SortPaginateContext.Provider>
    </FilterContext.Provider>
  );
}
