import React, { memo, useCallback, useContext } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import FilterFactory, { FilterType } from './FilterFactory';
import { RESPONSIVE_DRAWER_HEIGHT } from '../../Constants';
import styles from '../Filter/Filter.module.scss';
import { DeliveryFilterDTO } from '../../api/generated';
import { columnNameMapping } from '../../pages/Documentation';
import { DeliveriesContext, FilterContext } from '../../context/Documentation.context';

const filterItems: { key: keyof DeliveryFilterDTO; type: FilterType; options?: Record<string, any> }[] = [
  { key: 'delivery_id', type: FilterType.StringSelect },
  { key: 'date', type: FilterType.DateRange },
  { key: 'avv', type: FilterType.StringSelect },
  { key: 'has_truck_scan_img', type: FilterType.EnumBoolean },
  { key: 'customer_id', type: FilterType.StringSelect },
  { key: 'material', type: FilterType.StringSelect },
  { key: 'netto_weight', type: FilterType.NumberRange, options: { unit: 't' } },
  { key: 'numberplate', type: FilterType.StringSelect },
];

export interface FilterPanelProps {
  onCloseDrawer: () => void;
}

function FilterPanel(props: FilterPanelProps) {
  const [openFilters, setOpenFilters] = React.useState<Record<string, boolean>>({});
  const filterDispatch = useContext(FilterContext).filterDispatch;
  const itemCount = useContext(DeliveriesContext).result?.total;

  const handleClick = (filter: string) => {
    setOpenFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleResetFilters = useCallback(() => {
    filterDispatch({ type: 'filter.reset' });
  }, [filterDispatch]);

  return (
    <Paper
      elevation={8}
      sx={{
        maxHeight: {
          lg: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px - 1rem)`,
          md: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px - 2rem)`,
        },
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: '10px',
      }}
    >
      <Box className={styles.filter_panel_header}>
        <Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <TuneIcon sx={{ mr: 1 }} />
          Filter
        </Typography>
        <IconButton
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            ml: 'auto',
            mr: 2,
          }}
          onClick={() => props.onCloseDrawer()}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <List>
          {filterItems.map((val, idx) => (
            <React.Fragment key={val.key}>
              {idx !== 0 && <Divider key={val.key} sx={{ width: '90%', margin: '0 auto' }} />}
              <ListItem disablePadding sx={{ width: '90%', margin: '0 auto' }}>
                <ListItemButton onClick={() => handleClick(val.key)}>
                  {openFilters[val.key] ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary={columnNameMapping[val.key]} sx={{ paddingLeft: '8px' }} />
                </ListItemButton>
              </ListItem>
              <Collapse in={openFilters[val.key]} unmountOnExit>
                <Box
                  className={`${styles.flex_container} ${styles.flex_vertical} ${styles.flex_container_width} ${styles.flex_container_height} ${styles.centered}`}
                >
                  <FilterFactory key={val.key} filterKey={val.key} type={val.type} options={val.options} />
                </Box>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          display: { lg: 'none', md: 'none', xs: 'flex' },
          position: 'sticky',
          bottom: 0,
          width: '100%',
          bgcolor: 'background.paper',
          flexDirection: 'row',
          justifySelf: 'flex-end',
          justifyContent: 'space-evenly',
          gap: 2,
          alignItems: 'center',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button variant='outlined' color='primary' sx={{ flex: 1, m: 1 }} onClick={handleResetFilters}>
          Zurücksetzen
        </Button>
        <Typography sx={{ flex: 1, m: 1 }} variant='body1' align='center'>
          {itemCount} Einträge
        </Typography>
      </Box>
    </Paper>
  );
}

export default memo(FilterPanel);
