import React, { memo, useCallback, useContext, useMemo } from 'react';
import FilterNumberRange from '../Filter/NumberRange';
import {
  BooleanFilterDTO,
  DateFilterDTO,
  DeliveryFilterDTO,
  DeliveryFilterOptionsDTO,
  FilterRangeOptionDTO,
  FloatFilterDTO,
  StringFilterDTO,
} from '../../api/generated';
import { ApiCallState } from '../../api';
import { FilterContext } from '../../context/Documentation.context';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime, ToISOTimeOptions } from 'luxon';
import FilterDateRange from '../Filter/DateRange';
import FilterEnumOne from '../Filter/EnumOne';
import FilterStringSelect from '../Filter/StringSelect';

export enum FilterType {
  NumberRange = 'NumberRange',
  DateRange = 'DateRange',
  StringSelect = 'StringSelect',
  StringAutofill = 'StringAutofill',
  EnumBoolean = 'EnumBoolean',
}

export interface FilterFactoryProps {
  type: FilterType;
  filterKey: keyof DeliveryFilterDTO;
  options?: Record<string, any>;
}

const NumberRangeFilter = memo((props: FilterFactoryProps) => {
  const { filterState, filterDispatch } = useContext(FilterContext);

  const handleFilterChange = useCallback(
    (value: [number | null, number | null]) => {
      // convert tons to kg
      const convertedValue: [number | null, number | null] = value.map((v) =>
        v ? (props?.options?.unit === 't' ? v * 1000 : v) : null
      ) as [number | null, number | null];

      let range: FloatFilterDTO = {};
      if (convertedValue[0] && !convertedValue[1]) {
        range = { range: { gte: convertedValue[0] } };
      } else if (!convertedValue[0] && convertedValue[1]) {
        range = { range: { lte: convertedValue[1] } };
      } else if (convertedValue[0] && convertedValue[1]) {
        range = { range: { gte: convertedValue[0], lte: convertedValue[1] } };
      }

      if (range.range) {
        // set filter
        filterDispatch({
          type: 'filter.upsert',
          payload: { key: props.filterKey, data: range },
        });
      } else {
        // remove filter
        filterDispatch({
          type: 'filter.remove',
          payload: { key: props.filterKey },
        });
      }
    },
    [props.options?.unit, props.filterKey, filterDispatch]
  );

  const value: [number | null, number | null] = React.useMemo(() => {
    const range = (filterState.filter[props.filterKey] as FloatFilterDTO)?.range;
    const gte = range?.gte;
    const lte = range?.lte;
    return [gte || null, lte || null];
  }, [filterState.filter, props.filterKey]);

  if (props.options?.unit === 't') {
    value[0] = value[0] ? value[0] / 1000 : null;
    value[1] = value[1] ? value[1] / 1000 : null;
  }

  const valueRange = React.useMemo(() => {
    let range: ApiCallState<[number, number]> = {};
    const filterKey = props.filterKey as keyof DeliveryFilterOptionsDTO;
    if (filterState.filterOptions.result?.[filterKey]) {
      range = {
        result: [
          (filterState.filterOptions.result[filterKey] as FilterRangeOptionDTO)?.min as unknown as number,
          (filterState.filterOptions.result[filterKey] as FilterRangeOptionDTO)?.max as unknown as number,
        ],
      };

      // convert kg to tons
      range.result = range?.result?.map((v) => (props.options?.unit === 't' ? v / 1000 : v)) as [number, number];

      // floor min, ceil max
      range.result = range.result.map((v) => (v ? (v < 1 ? Math.floor(v) : Math.ceil(v)) : v)) as [number, number];
    }
    return range;
  }, [filterState.filterOptions.result, props.filterKey, props.options?.unit]);

  return (
    <FilterNumberRange
      filterKey={props.filterKey}
      onValueChange={handleFilterChange}
      value={value}
      valueRange={valueRange}
      {...props.options}
    />
  );
});

const DateRangeFilter = memo((props: FilterFactoryProps) => {
  const { filterState, filterDispatch } = useContext(FilterContext);

  const handleFilterChange = useCallback(
    (value: DateRange<DateTime>) => {
      const dateFormatOptions: ToISOTimeOptions = {};
      let filter: DateFilterDTO = {};
      if (value[0] && !value[1]) {
        filter = { range: { gte: value[0].toISO(dateFormatOptions) || '' } };
      } else if (!value[0] && value[1]) {
        filter = { range: { lt: value[1].toISO(dateFormatOptions) || '' } };
      } else if (value[0] && value[1]) {
        filter = {
          range: { gte: value[0].toISO(dateFormatOptions) || '', lt: value[1].toISO(dateFormatOptions) || '' },
        };
      }

      if (filter.range) {
        filterDispatch({
          type: 'filter.upsert',
          payload: { key: props.filterKey, data: filter },
        });
      } else {
        filterDispatch({
          type: 'filter.remove',
          payload: { key: props.filterKey },
        });
      }
    },
    [props.filterKey, filterDispatch]
  );

  const value: DateRange<DateTime> = useMemo(() => {
    const range = (filterState.filter[props.filterKey] as DateFilterDTO)?.range;
    const gte = range?.gte;
    const gteDateTime = (gte && DateTime.fromISO(gte)) || null;
    const lt = range?.lt;
    const ltDateTime = (lt && DateTime.fromISO(lt)) || null;
    return [gteDateTime, ltDateTime];
  }, [filterState.filter, props.filterKey]);

  const valueRange = React.useMemo(() => {
    let range: [DateTime | undefined, DateTime | undefined] = [undefined, undefined];
    const filterKey = props.filterKey as keyof DeliveryFilterOptionsDTO;
    if (filterState.filterOptions.result?.[filterKey]) {
      const min = (filterState.filterOptions.result[filterKey] as FilterRangeOptionDTO)?.min as unknown as string;
      const max = (filterState.filterOptions.result[filterKey] as FilterRangeOptionDTO)?.max as unknown as string;
      range = [min ? DateTime.fromISO(min) : undefined, max ? DateTime.fromISO(max) : undefined];
    }
    return range;
  }, [filterState.filterOptions.result, props.filterKey]);

  return (
    <FilterDateRange
      filterKey={props.filterKey}
      onValueChange={handleFilterChange}
      value={value}
      dateRange={valueRange}
      {...props.options}
    />
  );
});

const StringSelectFilter = memo((props: FilterFactoryProps) => {
  const { filterState, filterDispatch } = useContext(FilterContext);

  const handleFilterChange = useCallback(
    (value: string[]) => {
      let filter: StringFilterDTO = {};
      if (value.length > 0) {
        filter = { in: value };
      }

      if (filter.in) {
        filterDispatch({
          type: 'filter.upsert',
          payload: { key: props.filterKey, data: filter },
        });
      } else {
        filterDispatch({
          type: 'filter.remove',
          payload: { key: props.filterKey },
        });
      }
    },
    [filterDispatch, props.filterKey]
  );

  const value = useMemo(
    () => (filterState.filter[props.filterKey] as StringFilterDTO)?.in || [],
    [filterState.filter, props.filterKey]
  );

  const options: ApiCallState<Array<{ value: string; count?: number }>> = useMemo(() => {
    const filterArray = filterState.filterOptions.result?.[props.filterKey as keyof DeliveryFilterOptionsDTO];
    if (Array.isArray(filterArray)) {
      const ops = filterArray.map((val) => {
        const { value, count } = val as unknown as { value: string | number; count: number };
        return {
          value: value === null ? 'Ohne' : value.toString(),
          count: count,
        };
      });
      return { result: ops.sort((a, b) => (a.value > b.value ? 1 : -1)) };
    }
    return {};
  }, [filterState.filterOptions.result, props.filterKey]);

  return <FilterStringSelect options={options} onValueChange={handleFilterChange} value={value} {...props.options} />;
});

const EnumBooleanFilter = memo((props: FilterFactoryProps) => {
  const { filterState, filterDispatch } = useContext(FilterContext);

  const handleEnumBooleanFilterChange = useCallback(
    (value: boolean | string) => {
      if (typeof value === 'string') {
        value = value.toLowerCase() === 'true';
      }

      let filter: BooleanFilterDTO = {};
      if (value !== undefined) {
        filter = { eq: value };
      }

      if (filter.eq !== undefined) {
        filterDispatch({
          type: 'filter.upsert',
          payload: { key: props.filterKey, data: filter },
        });
      } else {
        filterDispatch({
          type: 'filter.remove',
          payload: { key: props.filterKey },
        });
      }
    },
    [props.filterKey, filterDispatch]
  );

  const value = useMemo(() => {
    const eq = (filterState.filter[props.filterKey] as BooleanFilterDTO)?.eq;
    return eq === undefined ? undefined : eq ? 'true' : 'false';
  }, [props.filterKey, filterState.filter]);

  const valueOptions = useMemo(() => {
    const filterArray = filterState.filterOptions.result?.[props.filterKey as keyof DeliveryFilterOptionsDTO];

    return Array.isArray(filterArray)
      ? filterArray.map((val) => {
          const { value, count } = val as unknown as { value: string; count: number };
          return {
            value: value,
            name: value ? 'Ja' : 'Nein',
            count: count,
          };
        })
      : [];
  }, [filterState.filterOptions.result, props.filterKey]);

  return (
    <FilterEnumOne
      filterKey={props.filterKey}
      options={valueOptions}
      value={value}
      onValueChange={handleEnumBooleanFilterChange}
      {...props.options}
    />
  );
});

function FilterFactory(props: FilterFactoryProps): JSX.Element {
  switch (props.type) {
    case FilterType.NumberRange:
      return <NumberRangeFilter {...props} />;
    case FilterType.DateRange:
      return <DateRangeFilter {...props} />;
    case FilterType.StringSelect:
      return <StringSelectFilter {...props} />;
    case FilterType.EnumBoolean:
      return <EnumBooleanFilter {...props} />;
    default:
      return <div>Unsupported filter type</div>;
  }
}

export default memo(FilterFactory);
