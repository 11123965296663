import { useState, useEffect, useContext } from 'react';
import { Table } from '../components/Documentation/Table';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { Box, Drawer, Grid, useMediaQuery, useTheme } from '@mui/material';
import { DetailView } from '../components/DetailView/DetailView';
import { RESPONSIVE_DRAWER_HEIGHT, RESPONSIVE_DRAWER_WIDTH } from '../Constants';
import { ApiCallState } from '../api';
import { DeliveryDTO, DeliveryFilterDTO } from '../api/generated';
import FilterPanel from '../components/Documentation/FilterPanel';
import FilterHeader from '../components/Documentation/FilterHeader';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DeliveriesContext } from '../context/Documentation.context';

export const columnNameMapping: Partial<Record<keyof DeliveryDTO | keyof DeliveryFilterDTO, string>> = {
  id: '_id',
  avv: 'AVV',
  construction_site: 'Baustelle',
  construction_site_id: 'Baustelle ID',
  customer_id: 'Kunde ID',
  date: 'Datum',
  delivery_id: 'Wiegeschein',
  material: 'Sorte',
  material_id: 'Sorte ID',
  netto_weight: 'Gewicht',
  numberplate: 'Fahrzeug',
  supplier: 'Kunde',
  has_truck_scan_img: 'Bild',
};

export function Documentation() {
  const [delivery, setDelivery] = useState<ApiCallState<DeliveryDTO>>();

  const [isOverlayShown, setOverlayShown] = useState(false);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [currentlySelectedRowIndex, setCurrentlySelectedRowIndex] = useState<number>();
  const [firstElementInTableSelected, setFirstElementInTableSelected] = useState<boolean>(false);
  const [lastElementInTableSelected, setLastElementInTableSelected] = useState<boolean>(false);

  const padding: number = 1; // The unit is rem
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const totalNumberOfDeliveries: number | undefined = useContext(DeliveriesContext).result?.total;
  const tablePageSize: number = 100;

  useEffect(() => {
    if (isMdUp) {
      setFilterDrawerOpen(false);
    }
  }, [isMdUp]);

  enum RequestedDelivery {
    NEXT,
    PREVIOUS,
  }

  const isFirstIndexInVisibleTable = (index: number) => index === 0;

  // (totalNumberOfDeliveries!! % tablePageSize) - 1 is the index of the last item on the last page
  // This is done because the last page may not have $tablePageSize items on it
  // TablePageSize is the last item on every other page that has $tablePageSize items on it
  const isLastIndexInVisibleTable = (index: number) =>
    index === (totalNumberOfDeliveries!! % tablePageSize) - 1 || index === tablePageSize - 1;

  const checkForFirstAndLastElementSelected = (indexOfRequestedRow: number): void => {
    setFirstElementInTableSelected(isFirstIndexInVisibleTable(indexOfRequestedRow));
    setLastElementInTableSelected(isLastIndexInVisibleTable(indexOfRequestedRow));
  };

  const handleClickOnDataGridRow = (value: DeliveryDTO, newlySelectedRowIndex: number) => {
    setDelivery({ result: value });
    setCurrentlySelectedRowIndex(newlySelectedRowIndex);
    if (!isOverlayShown) setOverlayShown(true);
    checkForFirstAndLastElementSelected(newlySelectedRowIndex);
  };

  function handleDifferentDeliveryRequested(requestedDelivery: RequestedDelivery) {
    const indexOfRequestedRow =
      requestedDelivery === RequestedDelivery.NEXT ? currentlySelectedRowIndex!! + 1 : currentlySelectedRowIndex!! - 1;
    setCurrentlySelectedRowIndex(indexOfRequestedRow);
    checkForFirstAndLastElementSelected(indexOfRequestedRow);
  }

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='de'>
      <Box display={'flex'}>
        <ResponsiveDrawer />
        <Box
          sx={{
            height: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px - 2*${padding}rem)`,
            width: `calc(100% - 2*${padding}rem)`,
            top: RESPONSIVE_DRAWER_HEIGHT,
            position: 'absolute',
            padding: `${padding}rem`,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Grid container columnSpacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} md={3} lg={3} sx={{ display: { xs: 'none', md: 'block', ml: 2 } }}>
              <FilterPanel onCloseDrawer={toggleFilterDrawer} />
            </Grid>
            <Grid item xs={12} md={9} lg={9} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <FilterHeader onShowFilterPanel={toggleFilterDrawer} />
              <Table
                onRowSelect={handleClickOnDataGridRow}
                requestedRowIndex={currentlySelectedRowIndex!!}
                pageSize={tablePageSize}
              />
            </Grid>
          </Grid>
        </Box>
        <Drawer
          open={isOverlayShown}
          onClose={() => setOverlayShown(false)}
          anchor='right'
          ModalProps={{
            slotProps: {
              backdrop: {
                invisible: true,
              },
            },
          }}
          PaperProps={{
            sx: {
              height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
              width: { xs: '100%', lg: `calc(80% - ${RESPONSIVE_DRAWER_WIDTH}px)`, md: '80%' },
              top: RESPONSIVE_DRAWER_HEIGHT,
            },
          }}
        >
          <DetailView
            delivery={delivery!!}
            nextButtonEnabled={!lastElementInTableSelected}
            previousButtonEnabled={!firstElementInTableSelected}
            hideOverlay={() => setOverlayShown(false)}
            requestNextItem={() => handleDifferentDeliveryRequested(RequestedDelivery.NEXT)}
            requestPreviousItem={() => handleDifferentDeliveryRequested(RequestedDelivery.PREVIOUS)}
          />
        </Drawer>
        <Drawer
          anchor='left'
          open={isFilterDrawerOpen}
          onClose={toggleFilterDrawer}
          PaperProps={{
            sx: {
              width: {
                xs: '90%',
                sm: '60%',
              },
              top: {
                xs: RESPONSIVE_DRAWER_HEIGHT - 8,
                sm: RESPONSIVE_DRAWER_HEIGHT,
              },
              height: {
                xs: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px + 8px)`,
                sm: `calc(100% - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
              },
            },
          }}
        >
          <FilterPanel onCloseDrawer={toggleFilterDrawer} />
        </Drawer>
      </Box>
    </LocalizationProvider>
  );
}
